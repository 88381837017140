import { Component, OnInit } from '@angular/core';
import {  
  ModalController 
} from '@ionic/angular';
@Component({
  selector: 'app-metamask',
  templateUrl: './metamask.component.html',
  styleUrls: ['./metamask.component.scss']
})
export class MetamaskComponent implements OnInit {

  constructor(public modalController: ModalController) { }

  ngOnInit() {
  }

  openMetamask(){
    window.open('https://metamask.io/download');
    this.modalController.dismiss();
  }

}
