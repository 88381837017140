import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth'; 
import { AngularFirestore } from '@angular/fire/firestore';

export interface callback{
  uri: string
  address: string
  signature: any
  callback: any
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(public afAuth: AngularFireAuth,
    public firestore: AngularFirestore) { }

  getUser(): firebase.User {
    return this.afAuth.auth.currentUser;
  }
         

  loginUser(email: string, password: string): Promise<firebase.auth.UserCredential> {
    return this.afAuth.auth.signInWithEmailAndPassword(email, password);
  }

  signupUser(email: string, password: string): Promise<any> {
    return this.afAuth.auth
      .createUserWithEmailAndPassword(email, password)
      .then((newUserCredential: firebase.auth.UserCredential) => {
        firebase
          .firestore()
          .doc(`/userProfile/${newUserCredential.user.uid}`)
          .set({ email });
      })
      .catch(error => {
        console.error(error);
        throw new Error(error);
      });
  }

  resetPassword(email:string): Promise<void> {
    return this.afAuth.auth.sendPasswordResetEmail(email);
  }

  logoutUser():Promise<void> {
    return this.afAuth.auth.signOut();
  }

  //Digi-ID Methods

  // digiQR(){
  //   let nonce = this.randomString(18);
  //   let callback = '//dash.digibytecafe.com/callback';
  //   const digiId = new DigiID({nonce, callback});
  //   console.log(digiId.uri);
  //   return digiId.uri;
  // }

  // randomString(length) {
  //     let text = "";
  //     let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  //     for(let i = 0; i < length; i++) {
  //         text += possible.charAt(Math.floor(Math.random() * possible.length));
  //     }
  //     return text;
  // }
}
