import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicStorageModule } from '@ionic/storage';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { QRCodeModule } from 'angularx-qrcode';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore'; 
import { AngularFireAuthModule } from '@angular/fire/auth'; 
import { AngularFireStorageModule } from '@angular/fire/storage'; 
import { NgImageSliderModule } from 'ng-image-slider';
import { ProductComponent } from './product/product.component';
import { MetamaskComponent } from './metamask/metamask.component';
const firebaseConfig = {
  apiKey: "AIzaSyApt9Pd34x6ehHnKi7sJGkXt2JyQ6AN_ZU",
  authDomain: "originassurance.firebaseapp.com",
  databaseURL: "https://originassurance.firebaseio.com",
  projectId: "originassurance",
  storageBucket: "originassurance.appspot.com",
  messagingSenderId: "901159340365",
  appId: "1:901159340365:web:67d3c40e23a96edb"
};

@NgModule({
  declarations: [AppComponent, ProductComponent, MetamaskComponent],
  entryComponents: [ProductComponent, MetamaskComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    HttpModule,
    FormsModule,
    QRCodeModule,
    NgImageSliderModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    AngularFireModule.initializeApp(firebaseConfig), 
    AngularFireAuthModule,
    AngularFirestoreModule, 
    AngularFireStorageModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
