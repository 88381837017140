import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './services/user/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'welcome', pathMatch: 'full' },
  {
    path: 'home',
    loadChildren: './home/home.module#HomePageModule',
    canActivate: [AuthGuard],
  },
  { path: 'products/:id', 
    loadChildren: './detail/detail.module#DetailPageModule', 
    //canActivate: [AuthGuard], 
  },
  { 
    path: 'login', 
    loadChildren: './login/login.module#LoginPageModule' 
  },
  { path: 'profile', loadChildren: './profile/profile.module#ProfilePageModule', canActivate: [AuthGuard], },
  // { path: 'reset-password', loadChildren: './reset-password/reset-password.module#ResetPasswordPageModule' },
  // { path: 'signup', loadChildren: './signup/signup.module#SignupPageModule' },
  //{ path: 'products', loadChildren: './detail/detail.module#DetailPageModule', canActivate: [AuthGuard], },
  // { path: 'client', loadChildren: './client/client.module#ClientPageModule' },
  { path: 'token-engine', loadChildren: './token-engine/token-engine.module#TokenEnginePageModule', canActivate: [AuthGuard],},
  { path: 'portal', loadChildren: './portal/portal.module#PortalPageModule', canActivate: [AuthGuard],},
  // { path: 'etx-slide', loadChildren: './etx-slide/etx-slide.module#EtxSlidePageModule' },
  // { path: 'token-detail', loadChildren: './token-detail/token-detail.module#TokenDetailPageModule', canActivate: [AuthGuard], },
  // { path: 'token-tx/:id', loadChildren: './token-tx/token-tx.module#TokenTxPageModule', canActivate: [AuthGuard], },
  { path: 'kava', loadChildren: './kava/kava.module#KavaPageModule' },
  { path: 'welcome', loadChildren: './welcome/welcome.module#WelcomePageModule' },
  { path: 'erc20-engine', loadChildren: './erc20-engine/erc20-engine.module#Erc20EnginePageModule',canActivate: [AuthGuard],},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
