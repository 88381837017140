import { Component } from '@angular/core';
//import * as firebase from 'firebase/app';
import { Platform, MenuController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AuthService } from './services/user/auth.service';
import { Router } from '@angular/router';
import * as firebase from 'firebase/app';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  public appPages = [
    // {
    //   title: 'Agent Portal',
    //   url: '/portal',
    // },
    {
      title: 'Token Engine',
      url: '/token-engine',
      //icon: 'arrow-dropright-circle'
    },
    {
      title: 'Origin Assurance',
      url: '/home',
      //icon: 'arrow-dropright-circle'
    },
    // {
    //   title: 'BLOCKS Contracts',
    //   url: '/etx-slide',
    //   icon: 'arrow-dropright-circle'
    // },
    // {
    //   title: 'O.A. Client',
    //   url: '/client',
    //   icon: 'arrow-dropright-circle'
    // },
    {
      title: 'My Account',
      url: '/profile',
      icon: 'arrow-dropright-circle'
    },
  ];
  public user: boolean = false;
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private authService: AuthService,
    public router: Router,
    public menuCtrl: MenuController
  ) {
    this.initializeApp();
    this.authCheck();
    // var config = {
    //   apiKey: "AIzaSyDsXpRvAqUfGAoN7ArhwdOUKPwJGKlBpLI",
    //   authDomain: "digicafe-dashboard.firebaseapp.com",
    //   databaseURL: "https://digicafe-dashboard.firebaseio.com",
    //   projectId: "digicafe-dashboard",
    //   storageBucket: "digicafe-dashboard.appspot.com",
    //   messagingSenderId: "489467683111"
    // };
    // firebase.initializeApp(config);
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  logOut(): void {
    this.authService.logoutUser().then( () => {
      this.toggleMenu();
      this.router.navigateByUrl('/client');
    });
  }

  logIn(): void {
    this.toggleMenu();
    this.router.navigateByUrl('login');
  }

  toggleMenu() {
    this.menuCtrl.toggle();
  }

  authCheck(){
    return new Promise((resolve, reject) => {
      firebase.auth().onAuthStateChanged((user: firebase.User) => {
        if (user) {
          this.user = true;
          console.log(this.user);
          resolve(this.user);
        } else {
          this.user = false;
          console.log(this.user);
          resolve(this.user);
        }
      });
    });
  }
}
