import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';


@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  public userProfile: firebase.firestore.DocumentReference;
  public currentUser: firebase.User;

  constructor() {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.currentUser = user;
        this.userProfile = firebase.firestore().doc(`/userProfile/${user.uid}`);
      }
    });
  }

  getUserProfile(uid): firebase.firestore.DocumentReference {
    this.userProfile = firebase.firestore().doc(`/userProfile/${uid}`);
    return this.userProfile;
  }

  updateName(businessName: string): Promise<any> {
    return this.userProfile.update({ businessName });
  }

  updateBusinessEmail(businessEmail: string): Promise<any> {
    return this.userProfile.update({ businessEmail });
  }

  updateDigiByteAddress(digibyteAddress: string): Promise<any> {
    return this.userProfile.update({ digibyteAddress });
  }

  updateLitecoinAddress(litecoinAddress: string): Promise<any> {
    return this.userProfile.update({ litecoinAddress });
  }

  updateBitcoinAddress(bitcoinAddress: string): Promise<any> {
    return this.userProfile.update({ bitcoinAddress });
  }

  updateLogo(imageUrl: string): Promise<any> {
    return this.userProfile.update({ imageUrl });
  }

  updateDescription(description: string): Promise<any> {
    return this.userProfile.update({ description });
  }

  updateEmail(newEmail: string, password: string): Promise<any> {
    const credential: firebase.auth.AuthCredential = firebase.auth.EmailAuthProvider.credential(
      this.currentUser.email,
      password
    );
  
    return this.currentUser
      .reauthenticateAndRetrieveDataWithCredential(credential)
      .then(() => {
        this.currentUser.updateEmail(newEmail).then(() => {
          this.userProfile.update({ email: newEmail });
        });
      })
      .catch(error => {
        console.error(error);
      });
  }

  updatePassword(newPassword: string, oldPassword: string): Promise<any> {
    const credential: firebase.auth.AuthCredential = firebase.auth.EmailAuthProvider.credential(
      this.currentUser.email,
      oldPassword
    );
  
    return this.currentUser
      .reauthenticateAndRetrieveDataWithCredential(credential)
      .then(() => {
        this.currentUser.updatePassword(newPassword).then(() => {
          console.log('Password Changed');
        });
      })
      .catch(error => {
        console.error(error);
      });
  }
}
