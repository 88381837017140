import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Storage } from '@ionic/storage';
import * as digibyte from 'digibyte';
import * as explorers from 'digibyte-explorers';
import { environment } from 'src/environments/environment';
declare const window: any;

@Injectable({
  providedIn: 'root'
})
export class CryptoService {
  public coinPrice: string;
  public explorer: string;
  public explorerTx: string;
  public currency: string;
  public digibyteAddress: string;
  public litecoinAddress: string;
  public dashAddress: string;
  public liteCore: string;
  public liteCoreTx: string;
  public transactionId: any;
  public insight: any = new explorers.Insight();
  public confirmSuccess: boolean = false;
  public digiassets: string;
  public ipfs: string;
  constructor(private http: HttpClient, public storage: Storage) {
    this.coinPrice = 'https://api.coincap.io/v2/assets/';
    this.explorer = 'https://digiexplorer.info/api/addr/';
    this.explorerTx = 'https://digiexplorer.info/api/tx/';
    this.currency = 'https://api.exchangeratesapi.io/latest?base=USD';
    this.digibyteAddress = 'DNBKZvAtoEt3oZLrodcearoQvGa28xmxMQ';
    this.digiassets = 'http://testnetapi.digiassets.net';
    this.ipfs = 'https://ipfs.infura.io:5001/api/v0/add';
  }

  async uploadToIpfs(file:any){
    console.log(file)
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post(this.ipfs, formData).toPromise();
  }

  async getDigiByteAddr(address): Promise<any> {
    return this.http.get(`${this.explorer}${address}`).toPromise();
  }
  async getDigiByteUtxo(address): Promise<any> {
    return this.http.get(`${this.explorer}${address}/utxo`).toPromise();
  }

  async getDigiByteTx(tx): Promise<any> {
    return this.http.get(`${this.explorerTx}${tx}`).toPromise();
  }

  public getCurrency(): Observable<any> {
    return this.http.get(this.currency);
  }

  public fetchPrice(coin): Observable<any> {
    return this.http.get(`${this.coinPrice}${coin}`);
  }

  async createTransaction(address: any, privateKey: any, amount: number, info: string) {
    return new Promise((res)=>{
      console.log(info.toString());
      let Unit = digibyte.Unit;
      let satoshis = Unit.fromDGB(amount).toSatoshis();
      let fee = 1000;
      this.getDigiByteUtxo(address).then(async data => {
        let utxos = await data;
        if(utxos.length == 0) {
          console.log('There are no funds available!');
          res(false);
        } else {
          let tx = new digibyte.Transaction();
          let x = 0;
          for(var i = 0; i < utxos.length; i++) {
            tx.from(utxos[i]);
            x = x + (utxos[i].amount * 100000000);
            console.log(x);
          }
          tx.to(this.digibyteAddress, x - fee);
          tx.fee(fee);
          tx.addData(info.toString());
          tx.sign(privateKey);
          console.log(tx.serialize());
          this.insight.broadcast(tx, (err: any, txId: any) => {
            if (txId) {
              this.transactionId = txId;
              let packet = {
                txid: this.transactionId
              }
              res(packet);
              console.log(this.transactionId);
              this.confirmSuccess = true;
              console.log(this.confirmSuccess);
            } else {
              this.confirmSuccess = false;
              console.log(err);
              let packet = {
                txid: this.confirmSuccess
              }
              res(packet);
            }
          });
        }
      });
    });  
  }

  broadcastTransaction(tx) {
    this.insight.broadcast(tx, (err: any, txId: any) => {
      if (txId) {
        this.transactionId = txId;
        console.log(this.transactionId);
        this.confirmSuccess = true;
        console.log(this.confirmSuccess);
      } else {
        this.confirmSuccess = false;
        console.log(err);
      }
    });
  }

  issueToken(asset){
    return this.http.post(`${this.digiassets}/issue`, asset)
  }

  async signToken(pk, hex){
    return new Promise(res=>{
      let tx = new digibyte.Transaction(hex.toString());
      tx.sign(pk);
      this.http.post(`${this.digiassets}/broadcast`, tx).subscribe(data=>{
        if(data){
          res(data);
        }
      }); 
    });
  }

  async getTransaction(tx): Promise<any>{
    return this.http.get(`${this.explorerTx}${tx}`).toPromise();
  }

  async getTokenContract(hash){
    return this.http.get(`https://api-ropsten.etherscan.io/api?module=account&action=txlistinternal&txhash=${hash}&apikey=${environment.etherscan}`).toPromise();
  }

  async getTokenLogs(hash){
    return this.http.get(`https://api-ropsten.etherscan.io/api?module=proxy&action=eth_getTransactionByHash&txhash=${hash}&apikey=${environment.etherscan}`).toPromise();
  }

  async getTokenUri(url){
    return this.http.get(url).toPromise();
  }

  async sendReceipt(email: any){
    console.log(email)
    return this.http.post(`${environment.sendgrid}`,{
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      email: email.email,
      product: email.name,
      symbol: email.symbol,
      description: email.description,
      imageUrl: email.imageUrl,
      metadata: email.metadata,
      txid: email.txid
    }).toPromise();
  }

  // broadcastAsset(tx){
  //   return this.http.post(`${this.digiassets}/broadcast`, tx);
  // }

}
