import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ModalController, AlertController, LoadingController } from '@ionic/angular';
import { TransactionRecordsService } from '../services/transaction-records.service';
import {Observable} from 'rxjs';
import {Storage} from '@ionic/storage';
import { AngularFireStorage } from '@angular/fire/storage';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import * as DigiByte from 'digibyte';
import * as Message from 'digibyte-message';
import * as makeHash from 'object-hash';
import {CryptoService} from '../crypto.service';
import { ProfileService } from '../services/user/profile.service';
@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {
  public imageURL: string = '';
  public uploadPercent: Observable<number>;
  public downloadURL: Observable<string>;
  public currentUser: firebase.User;
  public company: string = '';
  public product: string = '';
  public origin: string = '';
  public description: string = '';
  public image: string = '';
  public pk: string;
  public address: string;
  public proof: any;
  public message: string = 'block-30-OA';
  public amountDue: number = 1;
  public uri: string = '';
  public loading: any;
  public txId: any;
  //info: any = 'data';
  public hash: any;
  public newPacket: any;
  public userProfile: any;
  public logo: string;
  public companyDescription: string;

  constructor(public modalCtrl: ModalController, private afStorage: AngularFireStorage,
    public crypto:CryptoService, public alertCtrl: AlertController, public txService: TransactionRecordsService,
    public loadingCtrl: LoadingController, public storage: Storage, public chgDetect: ChangeDetectorRef,
    public profileService: ProfileService) { 

  }

  ngOnInit() {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.currentUser = user;
        this.getProfile(this.currentUser.uid);
      }
    });
  }

  getProfile(uid){
    this.profileService
    .getUserProfile(uid).get()
    .then(async (userProfileSnapshot) => {
      this.userProfile = userProfileSnapshot.data();
      if(this.userProfile){
        this.logo = this.userProfile.imageUrl;
        this.companyDescription = this.userProfile.description; 
      }
    });
  }

  addProduct(){
    let data = {
      company: this.company,
      product: this.product,
      origin: this.origin,
      description: this.description,
      image: this.imageURL,
      message: this.message,
      crypro: this.proof
    }
    this.modalCtrl.dismiss(data);
  }

  close(){
    this.modalCtrl.dismiss();
  }

  upload(event) {
    const file = event.target.files[0];
    const filePath = `products/${this.currentUser.uid}/${event.target.files[0].name}`;
    //const fileRef = this.afStorage.ref(filePath);
    const task = this.afStorage.upload(filePath, file);
    this.uploadPercent = task.percentageChanges();
    setTimeout(()=>{
      this.uploadPercent = null;
      this.getDownloadUrl(event.target.files[0].name);
    }, 3500);
  }

  getDownloadUrl(file){
    const filePath = `products/${this.currentUser.uid}/${file}`;
    const fileRef = this.afStorage.ref(filePath);
    this.downloadURL = fileRef.getDownloadURL();
    //this.images.push(this.downloadURL);
    this.downloadURL.toPromise().then(res=>{
      console.log(res);
      this.imageURL = res;
    });
  }

  removeImages(){
    if(this.imageURL != ''){
      this.afStorage.storage.refFromURL(this.imageURL).delete();
      this.imageURL = '';
      this.chgDetect.detectChanges();
    }
  }

  async createMessage(){
    let privateKey = DigiByte.PrivateKey();
    console.log(privateKey);
    let dgbAddress = privateKey.toAddress();
    console.log(dgbAddress);
    let exportPrivate = privateKey.toWIF();
    this.pk = exportPrivate;
    let exportAddress = dgbAddress.toString();
    this.address = exportAddress;
    let packet = {
      uniqueId: exportAddress,
      pk: exportPrivate,
      company: this.company,
      product: this.product,
      origin: this.origin,
      description: this.description,
    }
    this.hash = makeHash(packet);
    let signature = await Message(this.hash).sign(privateKey);
    console.log(this.hash);
    this.newPacket = {
      uniqueId: exportAddress,
      pk: exportPrivate,
      proof: packet,
      company: this.company,
      product: this.product,
      origin: this.origin,
      description: this.description,
      imageURL: this.imageURL,
      hash: this.hash,
      signature: signature
    }
    console.log(packet);
    console.log(this.newPacket);
    this.proof = this.newPacket;
    // this.crypto.fetchPrice('digibyte').subscribe((res: any)=>{
    //   console.log(res.data.priceUsd);
    //   if (res){
    //     let amtDue = 1 / res.data.priceUsd;
    //     console.log(amtDue);
    //     this.amountDue = parseFloat(amtDue.toFixed(4));
        this.uri = `digibyte:${this.address}?amount=${this.amountDue}`;
      //}
    //});
  }

  // async payAlert(){
  //   const alert = await this.alertCtrl.create({
  //     header: 'Confirm...',
  //     message: 'A unique hash of this product data will be saved on the blockchain.',
  //     buttons: [
  //       {
  //         text: 'Cancel',
  //         role: 'cancel',
  //         cssClass: 'secondary',
  //         handler: () => {
  //           console.log('Confirm Cancel');
  //         }
  //       }, {
  //         text: 'Proceed',
  //         handler: async () => {
  //             this.loading = await this.loadingCtrl.create({
  //               message: 'Creating Transaction...'
  //             });
  //             this.loading.present();
  //             setTimeout(()=>{
  //               this.crypto.createTransaction(this.address, this.pk, this.amountDue, this.proof).then((res: any)=>{
  //                 if(res.txId){
  //                   this.tx = res.txId;
  //                 }
  //               });
  //             },2000);
  //         }
  //       }
  //     ]
  //   });
  //   await alert.present();
  // }

  async presentConfirmSuccess(txid) {
    let alert = await this.alertCtrl.create({
      header: 'Transaction Successful!',
      message: `<a href=https://digiexplorer.info/tx/${txid}>${txid}</a>`,
      buttons: [
        {
          text: 'Dismiss',
          role: 'cancel',
          handler: () => {
            this.close();
          }
        }
      ]
    });
    return await alert.present();
  }

  async presentConfirmFail() {
    let alert = await this.alertCtrl.create({
      header: 'Transaction Failed!',
      message: 'Make sure you have sent DigiByte then retry or check your network settings.',
      buttons: [
        {
          text: 'Dismiss',
          role: 'cancel',
          handler: () => {

          }
        }
      ]
    });
    return await alert.present();
  }

  async addToBlockchain(){
    if (this.hash != undefined){
      this.loading = await this.loadingCtrl.create({
        message: 'Creating Transaction...'
      });
      this.loading.present();
      setTimeout(()=>{
          this.crypto.createTransaction(this.address, this.pk, this.amountDue, this.hash).then(async (res: any)=>{
            if(res.txid){
              this.txId = res.txid;
              this.newPacket.logo = this.logo;
              this.newPacket.coDescription = this.companyDescription; 
              this.newPacket.txId = await this.txId;
              console.log(this.newPacket);
              this.txService.createPublicTx(this.newPacket);
              this.loading.dismiss();
              this.presentConfirmSuccess(this.txId);
            } else{
              this.loading.dismiss();
              this.presentConfirmFail();
            }
          })
      },7000);  
    }
  }

}
