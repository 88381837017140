import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth'; 
import {AngularFirestore, 
  AngularFirestoreCollection, 
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
//import * as firebase from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class TransactionRecordsService {

  public transactionList: AngularFirestoreCollection<any>; 
  public publicList: AngularFirestoreCollection<any>;
  public userId: string;
  public newTxRef: firebase.firestore.DocumentReference;
  public tokenList: AngularFirestoreCollection<any>;
 
  constructor(private afAuth: AngularFireAuth, 
    private firestore: AngularFirestore) { 
      this.afAuth.authState.subscribe(user => {
        if(user.uid){
          this.userId = user.uid; 
          this.tokenList = this.firestore.collection(`/tokens/${user.uid}/tokenList`);  
        }  
      });
      this.publicList = this.firestore.collection(`/productList`);
  }

  // getTxList(): AngularFirestoreCollection<any> {
  //   return this.transactionList; 
  // }

  // getTx(txId: string, userId: string): AngularFirestoreDocument<any> {
  //   return this.firestore.doc(`/userProfile/${userId}/txList/${txId}`);
  // }

  getPublicListTx(txId: string): AngularFirestoreDocument<any> {
    return this.firestore.doc(`/productList/${txId}`);
  }

  getPublicList(): AngularFirestoreCollection<any> {
    return this.publicList; 
  }

  removeTx(txId: string): Promise<any> { 
    return this.publicList.doc(txId).delete();
  }

  getTokenList(uid): AngularFirestoreCollection<any> {
    this.tokenList = this.firestore.collection(`/tokens/${uid}/tokenList`);
    console.log(this.tokenList);
    return this.tokenList;
  }

  getTokenTx(id: string): AngularFirestoreDocument<any> {
    return this.tokenList.doc(id);
  }

  editTx(txId: string, pass: any): Promise<any>{
    return this.publicList.doc(txId).update({
      id: txId,
      uid: this.userId,
      uniqueId: pass.uniqueId,
      signature: pass.signature,
      message: pass.message,
      company: pass.company,
      product: pass.product,
      origin: pass.origin
    }); 
  }

  async createTokenTx(pass: any): Promise<any> {
    const txRef: firebase.firestore.DocumentReference = await this.tokenList.add({});
    return txRef.update({
      id: txRef.id,
      txId: pass.txId,
      name: pass.name,
      symbol: pass.symbol,
      owner: pass.owner,
      user: pass.uid,
      supply: pass.supply,
    }); 
  }

  // async createTx(pass: any): Promise<any> {
  //   this.newTxRef = await this.transactionList.add({}); 
  //   return this.newTxRef.update({
  //     id: this.newTxRef.id,
  //     uniqueId: pass.uniqueId,
  //     signature: pass.signature,
  //     message: pass.message,
  //     company: pass.company,
  //     product: pass.product,
  //     origin: pass.origin
  //   });
  // }

  async createPublicTx(pass: any): Promise<any> {
    const txRef: firebase.firestore.DocumentReference = await this.publicList.add({});
    return txRef.update({
      id: txRef.id,
      uid: this.userId,
      company: pass.company,
      product: pass.product,
      origin: pass.origin,
      description: pass.description,
      imageURL: pass.imageURL ? pass.imageURL : "",
      coDescription: pass.coDescription,
      logo: pass.logo,
      dataTx: pass.dataTx
    }); 
  }  
}
